<template>
  <div class="stepContent commonClass">
    <div class="flex relative">
      <h3 class="titHead">动物检疫</h3>
      <el-button size="small" class="export" @click="returnDetail">返回</el-button>
    </div>
    <div class="detail">
      <table class="table">
        <tr>
          <th colspan="2">动物品系</th>
          <td>{{ activeInfo.strain }}</td>
          <th colspan="2">实验编号</th>
          <td>{{ number }}</td>
          <th>实验负责人</th>
          <td width="100">{{ dirNickname }}</td>
          <th width="100">入驻日期</th>
          <td colspan="3">
            {{ activeInfo.inTime | formatDay }}
          </td>
        </tr>
        <tr>
          <th colspan="4">供应商</th>
          <td colspan="6"> {{ activeInfo.aniSupply.supplier ? activeInfo.aniSupply.supplier : '-' }}</td>
        </tr>
        <tr>
          <td colspan="10">合格动物</td>
        </tr>
        <tr>
          <td colspan="2" :rowspan="rows">耳号</td>
          <td :rowspan="rows">笼架编号</td>
          <td :rowspan="rows">笼舍编号</td>
          <td :colspan="typeList.length" v-if="activeInfo.isGene==1">基因类型</td>
          <td :rowspan="rows">性别</td>
          <td :rowspan="rows">出生日期</td>
          <td colspan="3" :rowspan="rows">观察状态</td>
        </tr>
        <tr v-if="activeInfo.isGene==1">
          <td v-for="item in typeList" width="100">{{ item.title }}</td>
        </tr>
        <tr v-for="(item,index) in qualified" :key="index">
          <td colspan="2">{{ item.overbit }}</td>
          <td>{{ item.frameNo }}</td>
          <td>{{ item.cageNo }}</td>
          <td v-for="itemType in item.typeList" v-if="activeInfo.isGene==1">{{ itemType.type }}</td>
          <td> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <td>{{ item.birthDateTime | formatDay }}</td>
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.quaHandle }}</td>
        </tr>
        <tr>
          <td colspan="10">不合格动物</td>
        </tr>
        <tr>
          <td colspan="2" :rowspan="rows">耳号</td>
          <td :rowspan="rows">笼架编号</td>
          <td :rowspan="rows">笼舍编号</td>
          <td :colspan="typeList.length" v-if="activeInfo.isGene==1">基因类型</td>
          <td :rowspan="rows">性别</td>
          <td :rowspan="rows">出生日期</td>
          <td colspan="3" :rowspan="rows">观察状态</td>
        </tr>
        <tr v-if="activeInfo.isGene==1">
          <td v-for="item in typeList" width="100">{{ item.title }}</td>
        </tr>
        <tr v-for="(item,index) in unqualified" :key="item.id">
          <td colspan="2">{{ item.overbit }}</td>
          <td>{{ item.frameNo }}</td>
          <td>{{ item.cageNo }}</td>
          <td v-for="itemType in item.typeList" v-if="activeInfo.isGene==1">{{ itemType.type }}</td>
          <td> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <td>{{ item.birthDateTime | formatDay }}</td>
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.unquaHandle }}</td>
        </tr>
        <tr>
          <td colspan="3">检疫人</td>
          <td colspan="3">{{ activeInfo.aniQuar.applyerName }}</td>
          <td colspan="2">检疫时间</td>
          <td colspan="3">{{ activeInfo.aniQuar.applyTime | formatDay }}</td>
        </tr>
        <tr v-if="activeInfo.aniQuar.state==2">
          <td colspan="3">复核人</td>
          <td colspan="3">{{ activeInfo.aniQuar.reviewerName }}</td>
          <td colspan="2">复核时间</td>
          <td colspan="3">{{ activeInfo.aniQuar.reviewTime  | formatDay }}</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
export default {
  name: "quarantineFinish",
  props: ['id'],
  data() {
    return {
      rows: 1,
      number: "",
      dirNickname: "",
      activeInfo: {
        aniSupply: {},
        aniQuar: {}
      },
      unqualified: [],
      qualified: [],
      typeList: []
    }
  },
  mounted() {
    this.getQuarantine()
  },
  methods: {
    getQuarantine() {
      this.$get("/subject/quar/" + this.id).then(res => {
        if (res.status == 200) {
          // 基本信息
          this.number = res.data.number
          this.dirNickname = res.data.dirNickname
          this.activeInfo = res.data.aniOrder

          res.data.aniOrder.quarResultList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            // 筛选合格不合格
            if (obj.isQualify == 0) {
              this.unqualified.push(obj)
            } else {
              this.qualified.push(obj)
            }
          })
          let arr = this.activeInfo.geneKeys.split(',')
          arr.forEach(item => {
            this.typeList.push({
              title: item
            })
          })
          this.rows = res.data.aniOrder.isGene == 1 ? 2 : 1
        }
      })
    },
    returnDetail() {
      this.$emit('changeStatus', true, 1)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
